<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
        <el-breadcrumb-item>阅卷</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="yt-container yt-container-flex" :class="{ 'is-pack-up': isPackUp }">
      <div class="yt-content-tree">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>待批阅试卷</p>
          </div>
          <div class="tree" ref="wrapper">
            <el-tree ref="examList-tree" :data="list" node-key="examId" :highlight-current="true" @node-click="getReviewQ">
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="data.name" :disabled="data.name.length < 10" placement="top-start">
                  <div class="el-tree-node__label" @click="isFocus = data.examId">{{ data.name }}</div>
                </el-tooltip>
              </div>
            </el-tree>
          </div>
        </template>
      </div>
      <div class="yt-resize">
        ⋮
      </div>
      <div class="yt-content" v-loading="loading" element-loading-text="加载中">
        <div v-show="questionList.length !== 0" class="data">
          <div class="app-topic" v-for="(q, qIndex) in questionList" :key="qIndex">
            <div style="width: 100%;justify-content: space-between;margin-top: 5px;margin-left: 5px; ">
              <span>{{ qIndex + 1 }}、{{ removeHtml(q.stem) }}</span>
              <div>
                <el-button style="padding-top: 2px; padding-right: 10px" size="mini" type="text" @click="review(isFocus, q)">批阅</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="none" v-show="questionList.length === 0">
          暂无待批阅题目
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@/components/common/YTIcon'
import reviewApi from '../../../api/review'
export default {
  name: 'index',
  components: {
    YTIcon
  },
  data() {
    return {
      list: [],
      questionList: [],
      isPackUp: false,
      loading: false,
      isFocus: 0
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    this.$dragDiv()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'checkDetail') {
      sessionStorage.removeItem('getScorllData')
    }
    next()
  },
  methods: {
    getList() {
      reviewApi.reviewExamList().then(res => {
        this.list = res.res
        if (this.list.length !== 0) {
          if (this.$route.query.examId) {
            this.isFocus = Number(this.$route.query.examId)
            this.$nextTick(() => {
              this.$refs['examList-tree'].setCurrentKey(this.isFocus)
              this.$refs.wrapper.scrollTop = sessionStorage.getItem('getScorllData')
            })
          } else {
            this.isFocus = Number(this.list[0].examId)
          }
          this.getReviewQ(this.isFocus)
        }
      })
    },
    getReviewQ(id) {
      this.loading = true
      reviewApi.reviewExamQuestion(typeof id === 'number' ? id : id.examId).then(res => {
        this.questionList = res.res
        this.loading = false
      })
    },
    review(id, data) {
      this.$router.push({
        name: 'checkDetail',
        params: { examId: id, questionId: data.questionId }
      })
      let wrapperScorllTop = this.$refs.wrapper.scrollTop
      sessionStorage.setItem('getScorllData', wrapperScorllTop)
    },
    removeHtml(html) {
      return html
        .replace(/<(?:.|\n)*?>/gm, '')
        .replace(/(&rdquo;)/g, '"')
        .replace(/&ldquo;/g, '"')
        .replace(/&mdash;/g, '-')
        .replace(/&nbsp;/g, '')
        .replace(/&gt;/g, '>')
        .replace(/&lt;/g, '<')
        .replace(/<[\w\s"':=\/]*/, '')
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/dragDiv';
.yt-content {
  .app-topic {
    div {
      display: flex;
      span {
        width: 90%;
      }
    }
  }
}
</style>
